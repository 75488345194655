import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'

export interface Props {
  onClick: () => void
  variant?: Variant
}

export const ModalClose = memo(function ModalClose({
  onClick,
  variant = 'right',
}: Props) {
  return <Container onClick={onClick} variant={variant} />
})

const Container = styled.div<ContainerProps>`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  position: absolute;
  top: 4rem;
  right: 4.306vw;
  z-index: 2;
  transition: 0.3s ease-out;
  &:hover {
    &:before,
    &:after {
      transform: none;
    }
  }
  &:before,
  &:after {
    content: '';
    width: 24px;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 17px;
    right: 8px;
    transition: 0.3s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  ${({ theme, variant }) => {
    switch (variant) {
      case 'left':
        return css`
          left: 4.306vw;
          right: auto;
          &:before,
          &:after {
            background: ${theme.colors.variants.neutralDark4};
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    &:before,
    &:after {
      background: ${({ theme }) => theme.colors.variants.neutralDark4};
    }
  }

  @media (max-width: 767px) {
    top: 1.5rem;
    right: 1.5rem;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'left' | 'right'
