import styled from '@emotion/styled'
import { Props as BenefitsItemProps } from 'app/components/Benefits/Item'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import * as Icon from 'react-feather'
import Fade from 'react-reveal/Fade'

import { Syncrobox } from './Syncrobox'

export interface Props {
  IBEID?: number
  languagePrefix: string
  benefitsDescription?: string
  benefitsChecklist?: BenefitsItemProps[]
  modalStatus?: boolean
}

export const ModalIBE = memo(function ModalIBE({
  IBEID,
  languagePrefix,
  benefitsChecklist,
  benefitsDescription,
  modalStatus,
}: Props) {
  const benefitsRef = useRef(null)

  return (
    <Container row>
      <LeftSide dial={4}>
        <Fade left distance="7.5em" duration={600} when={modalStatus}>
          <Benefits ref={benefitsRef}>
            {benefitsDescription ? (
              <Description
                dangerouslySetInnerHTML={{ __html: benefitsDescription }}
              />
            ) : null}
            {benefitsChecklist
              ? benefitsChecklist.map((item, index) => (
                  <Benefit key={index} dial={4} row>
                    {item.icon ? (
                      <IconWrap dial={5}>
                        <SVG
                          src={item.icon}
                          width="57"
                          height="49"
                          alt={item.label}
                        />
                      </IconWrap>
                    ) : (
                      <Icon.CheckCircle />
                    )}
                    {item.label ? <Label>{item.label}</Label> : null}
                  </Benefit>
                ))
              : null}
          </Benefits>
        </Fade>
      </LeftSide>
      <RightSide>
        <Wrap>
          {!isMobile && IBEID ? (
            <Syncrobox IBEID={IBEID} languagePrefix={languagePrefix} />
          ) : null}
        </Wrap>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  height: 100%;
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 4rem 8.611vw 4rem 10.833vw;

  @media (max-width: 1199px) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
`

const Benefits = styled.div``

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin-bottom: 4.625rem;
  b,
  strong {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
    font-weight: 600;
  }
`

const Benefit = styled(FlexBox)`
  margin-bottom: 1.5rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  svg {
    width: auto;
    height: 1.875rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
    stroke-width: 1;
  }
`

const IconWrap = styled(FlexBox)`
  width: 1.875rem;
  height: 1.875rem;
`

const SVG = styled.img`
  width: 100%;
  height: auto;
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  line-height: 1.875rem;
  margin-left: 1.3125rem;
`

const RightSide = styled(FlexBox)`
  justify-content: center;
  width: 50%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
`

const Wrap = styled.div`
  padding: 4rem 0;
  margin: 0 auto;
`
