import styled from '@emotion/styled'
import { CookieMessage } from 'app/components/Common/CookieMessage'
import { Toolbars } from 'app/components/Common/Toolbars'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga'

export interface Props {
  address?: string
  email?: string
  IBEID?: number
  languageCode: string
  languagePrefix?: string | null
  logo?: string
  phone?: string
  siteName?: string
  vat?: string
}

export const Footer = memo(function Footer({
  address,
  email,
  IBEID,
  languageCode,
  languagePrefix,
  logo,
  phone,
  siteName,
  vat,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Head dial={7} row space="between" wrap>
          {/* {logo ? (
            <Logo src={logo} alt={siteName} width="123" height="123" />
          ) : null} */}
          <Cell>
            <Label>{siteName}</Label>
            {address ? <Address>{address}</Address> : null}
          </Cell>
          <Cell>
            <Label>{useVocabularyData('contacts', languageCode)}</Label>
            {phone ? (
              <Contact>
                <Phone
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      ReactGA.event({
                        category: 'Website',
                        action: 'Click',
                        label: 'Mobile Phone',
                      })
                    }
                  }}
                >
                  {`${useVocabularyData('phone', languageCode)} ${phone}`}
                </Phone>
              </Contact>
            ) : null}
            {email ? (
              <Contact>
                <Email
                  href={`mailto:${email}`}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Website',
                      action: 'Click',
                      label: 'Email address',
                    })
                  }}
                >
                  {email}
                </Email>
              </Contact>
            ) : null}
          </Cell>
        </Head>
        <Bottom dial={7} row space="between" wrap>
          <Cell>
            {siteName ? <SiteName>{siteName} - </SiteName> : null}
            {vat ? (
              <Vat>{`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
            ) : null}
            <Policy row wrap>
              <Link
                to={`${
                  languagePrefix ? `/${languagePrefix}` : ''
                }/privacy-policy`}
              >
                {useVocabularyData('privacy-policy', languageCode)}
              </Link>
              <Link
                to={`${
                  languagePrefix ? `/${languagePrefix}` : ''
                }/cookie-policy`}
              >
                {useVocabularyData('cookie-policy', languageCode)}
              </Link>
            </Policy>
          </Cell>
          <Credits href="https://www.qnt.it" target="_blank" rel="noopener">
            Webdesign <b>QNT Srl</b>
          </Credits>
        </Bottom>
      </Wrapper>
      <CookieMessage
        languageCode={languageCode}
        languagePrefix={languagePrefix}
      />
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="ipadVertical">
          <Toolbars
            languageCode={languageCode}
            languagePrefix={languagePrefix || null}
            email={email}
            phone={phone}
            IBEID={IBEID}
          />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding-bottom: 5.625rem;

  @media (max-width: 767px) {
    padding-bottom: 3.75rem;
  }
`

const Wrapper = styled.div`
  max-width: 56.5rem;
  margin: auto;
  padding: 13.3vh 1.5rem 0;

  @media (max-width: 1023px) {
    padding-top: 6rem;
  }

  @media (max-width: 767px) {
    padding-top: 3.75rem;
  }
`

const Head = styled(FlexBox)``

const Logo = styled.img`
  @media (max-width: 767px) {
    display: block;
    margin: auto;
  }
`

const Cell = styled.div`
  max-width: 19.444vw;

  @media (max-width: 1023px) {
    max-width: none;
  }

  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  margin-bottom: 0.5625rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-top: 3rem;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
`

const Contact = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
`

const Phone = styled.a`
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
`

const Email = styled.a`
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
`

const Bottom = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.25rem;
  margin-top: 13.3vh;
  > div {
    max-width: none;
  }

  @media (max-width: 1023px) {
    margin-top: 6rem;
  }

  @media (max-width: 767px) {
    margin-top: 3.375rem;
  }
`

const SiteName = styled.span``

const Vat = styled.span``

const Policy = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-top: 0.6875rem;
  a {
    margin-right: 1.125rem;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`

const Credits = styled.a`
  b {
    font-weight: 600;
  }

  @media (max-width: 767px) {
    margin: 0.75rem auto 0;
  }
`
