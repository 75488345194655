import { Footer as FooterComponent } from 'app/components/Common/Footer'
import { graphql, StaticQuery } from 'gatsby'
import React, { memo } from 'react'

import { getFooterProps } from './getFooterProps'

export interface Props {
  languageCode: string
  languagePrefix?: string | null
}

export const Footer = memo(function Footer({ languageCode }: Props) {
  return (
    <StaticQuery
      query={graphql`
        query FooterData {
          site {
            siteMetadata {
              title
            }
          }
          logo: allFile(filter: { name: { eq: "logo" } }) {
            nodes {
              publicURL
            }
          }
          cms {
            company {
              address
              country
              email
              locality
              phone
              postal_code
              region
              vat
              ibeid
            }
          }
        }
      `}
      render={(data) => {
        const props = getFooterProps(data, languageCode)

        return props ? <FooterComponent {...props} /> : null
      }}
    />
  )
})
