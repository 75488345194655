import styled from '@emotion/styled'
import { theme } from 'app/theme'
import React, { memo, useEffect } from 'react'

export interface Props {
  IBEID?: number
  languagePrefix: string
}

export const Syncrobox = memo(function Syncrobox({
  IBEID = 1,
  languagePrefix,
}: Props) {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true

    script.innerHTML = `
    (function (i, s, o, g, r, a, m) {
        i['SBSyncroBoxParam'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://cdn.simplebooking.it/search-box-script.axd?IDA=${IBEID}','SBSyncroBox');
    SBSyncroBox({
        CodLang: '${languagePrefix.toLocaleUpperCase()}',
        
        Styles: {
          Theme: 'light-pink',
          FontFamily: "'${theme.fontFamily.paragraph}'",
          CustomBGColor: '${theme.colors.variants.neutralLight4}',
          CustomButtonBGColor: '${theme.colors.variants.primaryDark1}',
          CustomButtonHoverBGColor: '${theme.colors.getHoverColor(
            theme.colors.variants.primaryDark1,
          )}',
          CustomLinkColor: '${theme.colors.variants.neutralDark4}',
          CustomIconColor: '${theme.colors.variants.neutralDark4}',
          CustomBoxShadowColorFocus: '${theme.colors.variants.neutralDark4}',
          CustomBoxShadowColorHover: '${theme.colors.variants.neutralDark4}',
          CustomAccentColor: '${theme.colors.variants.primaryDark1}',
          CustomAccentColorHover: '${theme.colors.variants.primaryDark1}',
          CustomColorHover: '${theme.colors.variants.primaryDark1}',
          CustomLabelHoverColor: '${theme.colors.variants.primaryDark1}',
        },
        
    });
    `

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Container>
      <Widget id="sb-container" />
    </Container>
  )
})
const Container = styled.section`
  max-width: 31.125rem;

  @media (max-width: 1199px) {
    max-width: 400px;
  }

  #sb-container {
    .sb {
      padding: 0 !important;
    }
    .sb-custom-box-shadow-color,
    .sb-custom-box-shadow-color-hover,
    .focus .sb-custom-box-shadow-color-focus {
      box-shadow: none !important;
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.variants.neutralLight3} !important;
      padding: 0;
      &:before {
        display: none !important;
      }
      &:hover {
        box-shadow: none !important;
      }
    }
    .sb-custom-box-shadow-color:focus {
      box-shadow: none !important;
    }
    .sb-custom-widget-element-hover-bg-color:hover {
      background: ${({ theme }) =>
        theme.colors.variants.neutralLight1} !important;
    }
    .sb-custom-box-shadow-color {
      border-color: ${({ theme }) =>
        theme.colors.variants.neutralLight3} !important;
    }
    #sb-container_sb__form-field--checkavailability {
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.8125rem;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .sb__form-field-label {
    display: inline-block;
  }

  .sb__form-field-input {
    .sb__form-field-icon {
      opacity: 0.5;
    }
  }

  .sb__dates {
    display: flex;
    align-items: flex-end;
    .sb__form-field {
      width: 50%;
    }
    .sb__form-field--checkin,
    .sb__form-field--checkout {
      width: calc(50% - 1.5rem);
      .sb__form-field-label {
        color: ${({ theme }) => theme.colors.variants.neutralLight3};
        font-family: ${({ theme }) => theme.fontFamily.paragraph};
        font-size: 0.75rem;
        letter-spacing: 1px;
        line-height: 1.875rem;
        margin-bottom: 0.75rem;
        text-transform: uppercase;
      }
      .sb__form-field-month-year {
        font-weight: 700;
      }
      .sb__form-field-input {
        border-radius: 0 !important;
        box-shadow: none !important;
      }
    }
    .sb__form-field--checkout {
      margin-left: 3rem;
    }
    .sb__calendar {
      border-radius: 0 !important;
      box-shadow: none !important;
      border: 1px solid ${({ theme }) => theme.colors.variants.neutralLight1};
      margin-top: -1px;
    }
    .sb__calendar-btn {
      box-shadow: none !important;
      color: ${({ theme }) => theme.colors.variants.neutralLight1} !important;
      .icon {
        fill: ${({ theme }) => theme.colors.variants.neutralDark4} !important;
      }
    }
    .sb__calendar-days {
      .sb__calendar-day {
        border-radius: 0 !important;
      }
    }
  }

  .sb__guests {
    border: 1px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    border-radius: 0 !important;
    margin-top: -1px;
    padding: 1rem !important;
    .sb__panel-actions {
      button {
        margin-top: 0 !important;
      }
      .sb__btn--secondary {
        background: ${({ theme }) => theme.colors.variants.neutralLight3};
        &:hover {
          background: ${({ theme }) =>
            theme.colors.variants.neutralDark4} !important;
        }
      }
    }
  }

  .sb__guests-rooms {
    margin-top: 3.75rem;
    .sb__form-field {
      .sb__form-field-label {
        color: ${({ theme }) => theme.colors.variants.neutralLight3};
        font-family: ${({ theme }) => theme.fontFamily.paragraph};
        font-size: 0.75rem;
        letter-spacing: 1px;
        line-height: 1.875rem;
        margin-bottom: 0.75rem;
        text-transform: uppercase;
      }
      .sb__form-field-guests {
        font-weight: 700;
      }
      #sb-container_sb__form-field--guests {
        box-shadow: none !important;
        border-radius: 0 !important;
      }
    }
  }

  .sb__guests-room {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.variants.neutralLight3};
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    .sb__guests-room-header {
      margin-bottom: 1rem;
    }
    .sb__guests-room-label {
      font-family: ${({ theme }) => theme.fontFamily.heading};
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.variants.neutralDark4};
      line-height: 2rem;
    }
    .sb__guests-room-header-divider {
      display: none;
    }
    .sb__guests-children-age-select.sb-custom-bg-color {
      border-radius: 0 !important;
      padding-left: 0.5rem !important;
      text-align: center;
    }
  }

  .sb__guests-room-remove {
    width: 1.75rem;
    height: 1.75rem;
    border-left: 1px solid ${({ theme }) => theme.colors.variants.neutralLight1};
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    svg {
      transform: scale(2) translateY(1px);
      * {
        fill: ${({ theme }) => theme.colors.variants.primaryDark1} !important;
      }
    }
  }

  .sb__btn {
    border-radius: 0;
    &.sb__btn--block.sb__btn--verify {
      height: 56px;
      text-align: center;
      font-size: 1.125rem;
      margin-top: 3.5rem;
    }
  }

  .sb__footer {
    .sb__footer-actions {
      display: flex;
      width: 100%;
      > a {
        width: 70% !important;
        text-align: right !important;
        text-transform: capitalize;
      }
      a {
        font-size: 0.625rem;
        letter-spacing: 1px;
        line-height: 0.9375rem;
        margin-top: 1.5rem !important;
        text-align: left;
        .icon {
          display: none !important;
        }
      }
      .sb__footer-promo-wrapper {
        width: 30%;
        text-transform: uppercase;
      }
      .sb__footer-promo {
        display: flex;
        border: 1px solid ${({ theme }) => theme.colors.variants.neutralDark4};
        border-radius: 0 !important;
        padding: 1rem !important;
        input {
          height: 1.5rem;
          border-radius: 0 !important;
        }
      }
    }
    .sb__panel-actions {
      .sb__btn--secondary {
        background: ${({ theme }) => theme.colors.variants.neutralLight3};
      }
    }
  }
`

const Widget = styled.div``
