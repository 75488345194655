import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga'
import Fade from 'react-reveal/Fade'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  address?: string
  backgroundImage?: { [key: string]: any }
  country?: string
  email?: string
  IBEID?: number
  languageCode: string
  languagePrefix: string | null
  logo?: string
  locality?: string
  menuItems: MenuItemProps[]
  navigationStatus?: boolean
  phone?: string
  postalCode?: string
  region?: string
  siteName?: string
}

export const Navigation = memo(function Navigation({
  address,
  backgroundImage,
  country,
  email,
  IBEID,
  languageCode,
  languagePrefix,
  logo,
  locality,
  menuItems,
  navigationStatus,
  phone,
  postalCode,
  region,
  siteName,
}: Props) {
  const contactsRef = useRef(null)

  return (
    <Container row>
      <LeftSide>
        {menuItems ? (
          <Nav>
            <Fade left cascade duration={600} when={navigationStatus}>
              <NavList>
                {menuItems.map((item, index) => (
                  <MenuItem key={index} {...item} />
                ))}
              </NavList>
            </Fade>
          </Nav>
        ) : null}
        <LeftBottom
          className={navigationStatus ? 'visible' : undefined}
          ref={contactsRef}
          row
          space="between"
        >
          <Contacts>
            {address ? (
              <Address>{`${address} - ${postalCode} ${locality} ${region} ${country}`}</Address>
            ) : null}
            {phone ? (
              <ContactsItem>
                <Phone
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      ReactGA.event({
                        category: 'Website',
                        action: 'Click',
                        label: 'Mobile Phone',
                      })
                    }
                  }}
                >
                  {phone}
                </Phone>
              </ContactsItem>
            ) : null}
            {email ? (
              <Email
                href={`mailto:${email}`}
                onClick={() => {
                  ReactGA.event({
                    category: 'Website',
                    action: 'Click',
                    label: 'Email address',
                  })
                }}
              >
                {email}
              </Email>
            ) : null}
          </Contacts>
          {IBEID ? (
            <CTA
              arrow
              className={navigationStatus ? 'visible' : undefined}
              URL={`https://www.simplebooking.it/ibe/hotelbooking/search?hid=${IBEID}&lang=${
                languagePrefix ? languagePrefix.toLocaleUpperCase() : 'IT'
              }`}
              label={useVocabularyData('book', languageCode)}
              rel="noopener"
              target="_blank"
            />
          ) : null}
        </LeftBottom>
      </LeftSide>
      <RightSide dial={5}>
        {backgroundImage ? (
          <BackgroundImage
            src={backgroundImage.src}
            srcSet={backgroundImage.srcSet}
            width={backgroundImage.width}
            height={backgroundImage.height}
            alt={siteName}
          />
        ) : null}
        {logo ? (
          <Logo src={logo} alt={siteName} width="151" height="120" />
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 767px) {
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
  }
`

const LeftSide = styled(FlexBox)`
  width: 60%;
  height: 100%;
  padding: 4.625rem 5vw 5.25rem;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 3.75rem 1.5rem;
  }
`

const Nav = styled.nav`
  @media (max-width: 767px) {
    width: 100%;
  }
`

const NavList = styled.ul`
  height: 64vh;
  display: flex;
  flex-flow: column wrap;

  @media (max-width: 767px) {
    display: block;
    height: auto;
    text-align: center;
  }
`

const LeftBottom = styled(FlexBox)`
  width: calc(100% - 16.042vw);
  position: absolute;
  bottom: 5.25rem;
  left: 5vw;
  opacity: 0;
  transform: translateY(3.75rem);
  transition: 0.4s ease-out;
  &.visible {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.6s;
  }

  @media (max-width: 1023px) {
    width: calc(100% - 10vw);
  }

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    margin-top: 3.75rem;
    position: relative;
    bottom: auto;
    left: auto;
  }
`

const Contacts = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  line-height: 1.375rem;

  @media (max-width: 767px) {
    text-align: center;
  }
`

const ContactsItem = styled.div``

const CTA = styled(Button)`
  margin-top: 0;

  @media (max-width: 1023px) {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 1.5rem auto 3.75rem;
  }
`

const Address = styled.div``

const Phone = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

const Email = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

const RightSide = styled(FlexBox)`
  width: 40%;
  height: 100%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Logo = styled.img`
  width: auto;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`
